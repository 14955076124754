exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-camp-tafraout-js": () => import("./../../../src/pages/camp-tafraout.js" /* webpackChunkName: "component---src-pages-camp-tafraout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-heading-js": () => import("./../../../src/pages/heading.js" /* webpackChunkName: "component---src-pages-heading-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscription-js": () => import("./../../../src/pages/inscription.js" /* webpackChunkName: "component---src-pages-inscription-js" */),
  "component---src-pages-knr-events-js": () => import("./../../../src/pages/knr-events.js" /* webpackChunkName: "component---src-pages-knr-events-js" */),
  "component---src-pages-reglement-js": () => import("./../../../src/pages/reglement.js" /* webpackChunkName: "component---src-pages-reglement-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */)
}

